//icon size - w,h
$icon-hex-lg: 72px 83px;
$icon-hex-md: 56px 65px;
$icon-hex-sm: 27px 32px;

$icon-upic-lg: 119px 119px;
$icon-upic-md: 57px 57px;

$icon-question: 21px 21px;
$icon-calc: 14px 18px;
$icon-note: 15px 20px;

$icon-time-added: 28px 23px;
$icon-time-added-sm: 25px 21px;

$icon-time-limit: 20px 23px;
$icon-time-limit-sm: 15px 17.5px;

$icon-complete: 15px 15px;
$icon-expired: 15px 15px;

$icon-not-supported: 24px 20px;

$icon-lock: 14px 19px;

$icon-download: 20px 20px;
$icon-info: 20px 20px;
$icon-video: 20px 20px;
$icon-close: 40px 40px;

@mixin icon-size($size){
    width: nth($size, 1);
    min-width: nth($size, 1);
    max-width: nth($size, 1);

    height: nth($size, 2);
    min-height: nth($size, 2);
    max-height: nth($size, 2)
}

@mixin icon-background($filename, $size: null, $sizeSm: null){
    background-image: url("./assets/icons/#{$filename}");
    @if ($size) {
        @include icon-size($size);
    }

    @include media-breakpoint-down(md) {
        @if ($sizeSm) {
            @include icon-size($sizeSm);
        }
    }
}

@mixin svg-icon($name, $filename, $size: null, $sizeSm: null){
    .dashboard-icon-#{$name} {
        @include icon-background($filename, $size, $sizeSm);
        @extend .dashboard-icon;
    }
}

@mixin svg-hex-icon-size($sizename, $size){
    .dashboard-icon-hex-#{$sizename} {
        @include icon-size($size);
    }
}

@mixin svg-hex-icon($name, $filename){
    .dashboard-icon-#{$name} {
        @include icon-background($filename);
        @extend .dashboard-icon;
        @include icon-size($icon-hex-md);

        @include media-breakpoint-down(md) {
            @include icon-size($icon-hex-sm);
        }
    }
}

@include svg-hex-icon-size("lg", $icon-hex-lg);
@include svg-hex-icon-size("md", $icon-hex-md);
@include svg-hex-icon-size("sm", $icon-hex-sm);

@include svg-icon("upic-l", "profile-desktop.svg", $icon-upic-lg);
@include svg-icon("upic-m", "profile-desktop.svg", $icon-upic-md);

@include svg-hex-icon("logical", "logical.svg");
@include svg-hex-icon("numerical", "numerical.svg");
@include svg-hex-icon("checking", "checking.svg");
@include svg-hex-icon("verbal", "verbal.svg");
@include svg-hex-icon("dimensions", "dimensions.svg");
@include svg-hex-icon("aspects-styles", "aspects-styles.svg");
@include svg-hex-icon("drives", "drives.svg");
@include svg-hex-icon("drivers", "drivers.svg");
@include svg-hex-icon("traits", "traits.svg");
@include svg-hex-icon("kf4dCompetencies", "competencies.svg");
@include svg-hex-icon("elca", "competencies.svg");
@include svg-hex-icon("personality", "personality.svg");
@include svg-hex-icon("demographics", "demographics-dashboard.svg");
@include svg-hex-icon("experiences", "experiences.svg");
@include svg-hex-icon("preferences", "preferences.svg");
@include svg-hex-icon("sjt", "individual-exercises-desktop.svg");
@include svg-hex-icon("abstract-reasoning", "abstract-reasoning.svg");

@include svg-icon("complete", "complete-icon.svg", $icon-complete);
@include svg-icon("expired", "expired-icon.svg", $icon-expired);
@include svg-icon("not-supported", "device-not-supported.svg", $icon-not-supported);
@include svg-icon("pen", "icon-pen-paper.svg", $icon-note);
@include svg-icon("question", "inquiry.svg", $icon-question);
@include svg-icon("calc", "need-calclulator.svg", $icon-calc);
@include svg-icon("time-added", "time-added-desktop.svg", $icon-time-added, $icon-time-added-sm);
@include svg-icon("time-limit", "time-lilmit-desktop.svg", $icon-time-limit, $icon-time-limit-sm);
@include svg-icon("lock", "lock.svg", $icon-lock);
@include svg-icon("download", "download.svg", $icon-download);
@include svg-icon("info", "info.svg", $icon-info);
@include svg-icon("video", "video.svg", $icon-video);
@include svg-icon("close","x.svg" , $icon-close);
@include svg-icon("error", "error.png");

.dashboard-icon {
    display: inline-block;
    background-size: cover;
}